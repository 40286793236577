@media screen and (max-width: 992px) {
    .div-info {
        margin-top: 0;
    }
}

.div-body{
	background-image: url("fondo2.jpg");
    background-size: 100% 100%;
    min-height: 100vh;
    overflow: auto;
}

.banner {
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.body-info {
    background-color:#FFF;
    margin: auto;
    max-width: 500px;
    height:500px;
    border-radius:10px;
    text-align:center;
    padding: 2% 5% 1% 5%;
    box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.login-buttons{
    display:flex;
    flex-direction: column;
}

.btn-login {
    display: flex !important;
    justify-content: center;
    align-items: center;
}