.table-wrapper{
    border: 1px solid #E7EDFF;
    padding-top:3%;
    padding-left:3%;
    padding-right:3%;
}

.table-header{
    border-bottom: 1px solid #E7EDFF; 
    padding-bottom:2%
}

.table-row{
    border-radius: 5%;
    background-color: #E7EDFF;
    margin-top: 1%;
}

.table-row:hover{
    background-color: #C7CEE3;
    cursor: pointer;
}

.td-deadline{
    background-color: #F2F2F2;
    border-radius: 5px;
    padding-left:2%;
    padding-right:2%;
}

