.navbar{
    display: flex;
    flex-direction: column;
    width:20%;
}

.nav-link{
    margin-top: 10%;
    border-radius: 5%;
    color: #001251;
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    word-wrap: break-word;
}

.nav-link:hover{
    text-decoration: none;
    cursor: pointer;
    color: #001251;
    background-color: #E7EDFF;
}

.active{
    color: #001251 !important;
}
