.wrapper{
    border: 1px solid #E7EDFF;
    padding: 3%;
    margin-top:2%;
    border-radius: 10px;
}

.icon-space{
}

.project-locked{
  background-color: #C3C3C3;
}

.project-warning{
  background-color: #fff2a5;
}

.section-header{
}

.section-circle{
    position:relative;
}

.section-bar{
    background-color: #B7FFE0;
    width:5px;
    height: 140%;
    margin-top:-50px;
    margin-left:15px;
}

.section-body{
    background-color: #E7EDFF;
    border-radius: 5px;
    padding: 2%;
    margin-top:1%;
}

.kurios-btn{
    background-color: #B7FFE0;
    border-radius: 5px;
}
